const Constant = {
  domainList: {
    CHARGEMILES: "chargemiles",
    MOVEONEV: "moveonev",
    AMIK: "amikcharge",
    AMIKTECHNOLOGIES: "amiktechnologies",
    TECHVOLTNETWORK: "techvoltnetwork",
    AVVTECH: "avvtech",
  },
};
export default Constant;
